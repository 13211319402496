import React from "react";
import { getImageUrl } from "../../api/Api";
import Icon from "../Icon";
import Jump from "react-reveal/Jump";
import RevealFade from "react-reveal/Fade";

function MainPageHeader({ page, noFullHeight, style }) {
  const { data } = page;

  return (
    <header
      className={`${noFullHeight ? `nofullheight` : ``} mainpage-header`}
      style={style}
    >
      <div className="mainpage-header-content">
        <div className="mainpage-header-image">
          {data.TitleImage && (
            <RevealFade>
              <img src={getImageUrl(data.TitleImage)} alt="" />
            </RevealFade>
          )}
        </div>
        <div className="mainpage-header-text">
          <RevealFade>
            <h1 dangerouslySetInnerHTML={{ __html: data.Title }} />
            <div dangerouslySetInnerHTML={{ __html: data.IntroText }} />
          </RevealFade>
        </div>
        <div className="mainpage-header-icon">
          <Jump>
            <button>
              <Icon icon="chevron-down" />
            </button>
          </Jump>
        </div>
      </div>
    </header>
  );
}

export default MainPageHeader;
