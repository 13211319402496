import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import "react-slideshow-image/dist/styles.css";
import { initPage } from "../../api/Api";
import Loading from "../Loading";
import { Cell, Grid, Row } from "../FoundationGrid";
import MainPageHeader from "../mainpage/MainPageHeader";
import { translate } from "../../helpers/I18n";
import Icon from "../Icon";
import { Reveal } from "react-reveal";
import { getUrl } from "../../helpers/helpers";
import { Link } from "gatsby";

const identifier = "contact";

function ContactPage({ locale }) {
  const buttons = [
    {
      label: translate("contact.buttons.email.label", locale),
      url: `mailto:hi@patricktschirpke.com?subject=${translate(
        "contact.buttons.email.subject",
        locale
      )}`,
      icon: "envelope-o",
      class: "hollow",
    },
    {
      label: translate("contact.buttons.instagram.label", locale),
      url: "https://instagram.com/patricktschirpke",
      icon: "instagram",
      class: "hollow",
    },
    {
      label: translate("contact.buttons.facebook.label", locale),
      url: "https://facebook.com/patricktschirpke",
      icon: "facebook-square",
      class: "hollow",
    },
    {
      label: translate("contact.buttons.linkedin.label", locale),
      url: "https://linkedin.com/in/patricktschirpke",
      icon: "linkedin-square",
      class: "hollow",
    },
  ];

  const [page, setPage] = useState({
    identifier: identifier,
    nav: "contact",
    locale: locale,
    parts: { mainPageSections: [], mainPageSpecialSections: [] },
    ctas: {},
  });

  const init = () => {
    initPage(page, (page) => setPage({ ...page }));
  };

  useEffect(init, []);

  if (!page.data) return <Loading page={page} fullscreen />;

  return (
    <div className={`page main-page page-${page.identifier}`}>
      <MainLayout page={page}>
        <div className="center-vertically">
          <Grid>
            <Row>
              <Cell sm={24}>
                <MainPageHeader
                  page={page}
                  noFullHeight
                  style={{ padding: 0 }}
                />
                <div className="text-center"></div>
              </Cell>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Cell
                sm={24}
                className="text-center"
                style={{ paddingBottom: "60px" }}
              >
                <Reveal>
                  <Link
                    to={getUrl("appointment", locale)}
                    className="contact-buttons-button primary button"
                  >
                    <Icon icon="comments-o" left />{" "}
                    {translate("contact.buttons.appointment.label", locale)}
                  </Link>
                  <Buttons buttons={buttons} />
                </Reveal>
              </Cell>
            </Row>
          </Grid>
        </div>
      </MainLayout>
    </div>
  );
}

const Buttons = ({ buttons }) => {
  return (
    <div className="contact-buttons">
      {buttons.map((button, index) => {
        return (
          <a
            href={button.url}
            target="_blank"
            className={`contact-buttons-button primary button ${button.class}`}
            key={index}
          >
            <Icon icon={button.icon} left /> {button.label}
          </a>
        );
      })}
    </div>
  );
};

export default ContactPage;
